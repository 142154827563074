import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp'

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.enabledental-container': {
    '& .normal-text': {
      fontWeight: 300,
    },

    '& .savings-hero-section, .home-hero-variant-2, .checkout-hero-section, .plan-selection-section, .faq-hero-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        background: palette.background.header,
        '& .hero-header-subtitle': {
          color: palette.text.secondary,
        },
        marginTop: '60px',
      },
    '@media(max-width: 900px)': {
      '& .header-wrapper': {
        paddingBottom: '10px',
      },
    },

    '& .checkout-outer-container, .plan-selection-container': {
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        opacity: 1,
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        background: palette.text.highlight,
      },
    },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.background.secondary,

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '650px',
        '& .section-header': {
          '@media screen and (min-width: 768px)': {
            marginTop: '60px',
          },
        },
      },
      '& .hero-title': {
        fontWeight: 'normal',
      },
      '& .home-hero-subtitle': {
        maxWidth: 510,
      },
      '@media(max-width:900px)': {
        '& .hero-title': {
          fontSize: '34px',
        },
      }
    },

    /* Benefits - Styles */
    '& .definition-variant-2': {
      '& .benefits-wrapper': {
        '& .section-header__title': {
          fontWeight: 'normal',
        },
      },
      '& .definition-service-description': {
        maxWidth: 320,
        margin: '0 auto',
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      '& .subscription-bg': {
        height: '100%',
        background: palette.background.secondaryLight,
      },
      '& #pricing-benefit-section': {
        '& .section-header__title ': {
          fontWeight: 'normal'
        },
        '& .planCard': {
          border: 'none',
          '&:hover': {
            border: `1px solid ${palette.primary.main}`,
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .price-value': {
            color: palette.text.primary,
          },
        },
        '& .savings-summary-link': {
          textDecoration: 'underline',
          color: palette.primary.main,
          cursor: 'pointer',
        },
      },
      '& .react-multi-carousel-dot button': {
        background: palette.primary.main,
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: palette.text.highlight,
      '& .howitworks-title': {
        marginBottom: 0,
      },
      '& .how-cover-image': {
        height: 450,
      },
      '& .step-counter': {
        backgroundColor: palette.background.secondary,
        fontSize: 16,
      },
      '& .step-content': {
        maxWidth: 400
      },
      '& .step-counter': {
        borderRadius: '50%',
        backgroundColor: palette.background.steps,
        color: palette.text.highlight,
      },
  
      '& .step-started-button': {
        '& .right-icon': {
          color: palette.text.highlight,
        },
      },
      '@media(max-width: 900px)': {
        '& .how-cover-image': {
          height: '100%',
        },
      }
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      background: palette.background.secondaryLight,
      '& .section-header__title': {
        fontWeight: 'normal'
      },
      '& .testimonial-name': {
        color: palette.text.highlight,
      },
      '& .react-multi-carousel-list': {
        overflow: 'visible',
      },
      '& .react-multiple-carousel__arrow--left, .react-multiple-carousel__arrow--right': {
        background: palette.background.arrow,
        '&::before': {
          color: palette.text.arrow
        }
      },
      '@media(min-width: 900px)': {
        '& .react-multiple-carousel__arrow--left': {
          left: '24%',
        },
        '& .react-multiple-carousel__arrow--right': {
          right: '24%',
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        '& .section-header__title': {
          color: palette.text.primary,
        },
        '& .section-header__subtitle': {
          color: palette.text.primary,
        },
      },
      '& .service-plan-table': {
        '& .savings-topheader': {
          color: `${palette.text.highlight} !important`,
        },

        '& .savings-header': {
          color: palette.text.primary,
        },

        '& .savings-subheader': {
          backgroundColor: palette.background.main,
          color: palette.text.primary,
          fontWeight: 600,
        },
      },

      '& .savings-contact-card': {
        background: palette.background.footer,
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-title': {
        fontWeight: 'normal'
      },
      '& .hero-static-subtitle': {
        color: palette.text.secondary
      },
      '& .hero-static-bg': {
        backgroundColor: palette.text.highlight,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
        background: palette.background.light,
        border: `1px solid ${palette.primary.main}`,
        opacity: 1,
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${palette.primary.main}`,
        background: palette.text.highlight,
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .planCard': {
          border: `1px solid ${palette.primary.main}`,
          '&:hover': {
            color: palette.text.primary,
            background: palette.text.highlight,
          },
          '& .highlight-divider': {
            backgroundColor: palette.divider,
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
      },
      '& .hero-static-bg': {
        background: `${palette.background.footer} !important`
      },
      '& .primary-static-button': {
        background: palette.primary.main,
      },
      '& .react-multi-carousel-dot button': {
        background: palette.primary.main,
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
      '& .section': {
        marginLeft: 64
      },
      '@media(max-width: 900px)': {
        '& .section': {
          marginLeft: 0
        },
      }
    },
    '& .faqInnerContainer': {
      '& .accordion__collapsable-text': {
        color: palette.text.secondary
      },
    },
    '& .faq-contact-section':{
      '& .contact-card-subtitle': {
        color: palette.text.secondary
      }
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
      '& .termsText': {
        fontFamily: 'auto',
        fontWeight: 'bold',
      },
    },

    /* Contact Card - FAQ, Success Page */
    '& .faq-contact-wrapper': {
      background: palette.background.footer,
      '& .contact-img-wrapper': {
        width: '64px',
        maxWidth: '64px',
      },
      '& .provider-contact-details': {
        color: palette.text.secondary,
        fontWeight: 400
      }
    },
    '& .mail-link': {
      color: palette.text.primary,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },

    /* Stepper - styles */
    '& .customStepperRoot': {
      '& .MuiStepConnector-lineHorizontal': {
        borderColor: palette.primary.main,
      },
      '& .stepperIconActive': {
        color: `${palette.primary.main} !important`,
        '& .stepperIconText': {
          fill: palette.text.highlight,
        },
      },
      '& .stepperIconRoot': {
        color: 'transparent',
        borderColor: palette.primary.main,
        '& .stepperIconText': {
          fill: palette.primary.main,
        },
      },
      '& .stepperIconCompleted': {
        color: palette.primary.main,
      },
    },

    /* Footer style */
    '& .footer-wrapper': {
      background: palette.text.highlight,
      borderTop: `1px solid ${palette.divider}`,
      color: palette.text.primary,
      '& .footer-link, .copyrights-footer-link': {
        color: palette.text.primary,
      },
      '& .copyrights-footer': {
        borderTop: `1px solid ${palette.divider}`,
      },
    },
  },
};